import _ from 'lodash';
window._ = _;

import * as Popper from '@popperjs/core'
window.Popper = Popper

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

(function() {
    var stickyObserver = new IntersectionObserver(function(entries) {
        var header = document.querySelector("header");
        var pageTitle = document.querySelector(".page-title");
        var studyName = document.querySelector("#studyname");

        // no intersection with screen
        if(entries[0].intersectionRatio === 0) {
            header.classList.add("minimized");
            pageTitle.style.display = "";
        }
        // fully intersects with screen
        else if(entries[0].intersectionRatio === 1) {
            header.classList.remove("minimized");
            pageTitle.style.display = "none";
            if (studyName) {
                pageTitle.textContent = studyName.textContent;
            }
        }
    }, { threshold: [0, 1] });

    stickyObserver.observe(document.querySelector("#scrollmarker"));
})();



